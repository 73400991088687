export const btnMixins = {
    data(){
        return{
            btnList:[],
        }
    },
    activated(){
        //获取按钮权限
        this.$util.getButtons(this.btnMenuId).then(res=>{
            this.btnList = res.detail.map(item=>item.data.menuId)
        })
    },
    methods:{
        btnexist(name){
            let ishave = false
            this.btnList.forEach(item=>{
                if(item.includes(name)){
                    ishave = true
                }
            })
            return ishave
        },
        getButtons(btnMenuId) {
            this.$util.getButtons(btnMenuId).then(res=>{
                if(this.btnList.length > 0) {
                    let newBtn = []
                    newBtn = res.detail.map(item=>item.data.menuId)
                    this.btnList = this.btnList.concat(newBtn)
                    console.log(this.btnList)
                } else {
                    this.btnList = res.detail.map(item=>item.data.menuId)
                }
            })
        }
    },
}